<mat-toolbar color="primary" class="appbar mat-elevation-z4">
  <img class="logo" src="/assets/bsi-logo.svg" alt="logo">
  <span>{{ title() }}</span>

  <div class="spacer"></div>

  @if (canViewMembers()) {
      <button mat-icon-button [routerLink]="['members']">
        <mat-icon svgIcon="members" matTooltip="Members"></mat-icon>
      </button>
  }

  @if (canViewWorkspaces()) {
      <button mat-icon-button [routerLink]="['workspaces']">
        <mat-icon svgIcon="workspaces" matTooltip="Workspaces"></mat-icon>
      </button>
  }

  @if (!canViewMembers() && !canViewWorkspaces() && msalAuthenticated()) {
      <div class="dot-container">
        <div class="dot-white"></div>
        <div class="dot-white-ring"></div>
      </div>
  }

  @if (!msalAuthenticated()) {
  <button mat-icon-button (click)="onLogin()">
    <mat-icon svgIcon="login" matTooltip="Login"></mat-icon>
  </button>
  }
  @else {
  <button mat-icon-button (click)="onLogout()">
    <mat-icon svgIcon="logout" matTooltip="Logout"></mat-icon>
  </button>
  }
  <button mat-icon-button (click)="onAbout()">
    <mat-icon svgIcon="about" matTooltip="About"></mat-icon>
  </button>
</mat-toolbar>

<main>
  <router-outlet></router-outlet>
</main>
