import { Injectable } from '@angular/core';
import {
  Member,
  Document,
  Workspace,
  WorkspaceAccess,
  MemberAccess,
  ProgressUpdate,
  ProgressIterable,
} from '@core/models';
import { DocumentTypes, LevelPolicy, RecordTypes } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { v4 as uuidv4 } from 'uuid';
import { WorkspaceService } from './workspace.service';

export class UpdatePersonsInWorkspaceResult {
  constructor(
    public memberAccesses: MemberAccess[],
  ) { }
}

export class UpdateServiceAccountsInWorkspaceResult {
  constructor(
    public memberAccesses: MemberAccess[],
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly documentApiService: DocumentApiService,
  ) { }

  /**
   * Check if the workspace supports persons. This method will check if the workspace has a person
   * record type.
   * @param workspaceId The workspace to check
   * @returns True if the workspace supports persons, false otherwise
   */
  async doesWorkspaceSupportPersons(workspaceId: string): Promise<boolean> {
    // TODO: Allow backend to specify if workspace supports persons
    const recordTypes = await this.documentApiService.getAllRecordTypes(workspaceId);
    return recordTypes.find(rt => rt.name === RecordTypes.person) !== undefined;
  }

  /**
   * Check if the workspace supports persons. This method will check if the workspace has a person
   * record type.
   * @param workspaceId The workspace to check
   * @returns True if the workspace supports persons, false otherwise
   */
  async doesWorkspaceSupportServiceAccounts(workspaceId: string): Promise<boolean> {
    // TODO: Allow backend to specify if workspace supports persons
    const recordTypes = await this.documentApiService.getAllRecordTypes(workspaceId);
    return recordTypes.find(rt => rt.name === RecordTypes.serviceAccount) !== undefined;
  }

  /**
   * Update the members and persons in a workspace. This method will create missing members
   * and persons, update existing persons and unlink persons that are not in the list.
   * @param workspaceId The workspace to update
   * @param members Information about the members
   * @returns The updated members
   * @remarks
   * Service members are not updated.
   */
  async *updatePersonsInWorkspace(
    workspace: Workspace,
    members: Member[]): ProgressIterable<UpdatePersonsInWorkspaceResult> {

    const updatedMemberAccesses: MemberAccess[] = [];

    if (workspace.workspaceId === '') {
      yield new ProgressUpdate(0, 'No workspace to update', false);
      return;
    }

    yield new ProgressUpdate(0, `Fetching people in ${workspace.displayName}`);

    const personRecords = await this.documentApiService.getRecords(
      RecordTypes.person,
      workspace.workspaceId);

    // Delete persons that are not a member of the workspace
    let multiplier = 50 / personRecords.length;
    for (const person of personRecords) {
      if (!person.content.fields.memberId) {
        // Do not delete persons that are not members
        continue;
      }
      if (!members.some(m => m.memberId === person.content.fields.memberId || m.isServiceMember)) {
        // Delete person if member has no access or is a service member
        yield new ProgressUpdate(
          multiplier * personRecords.indexOf(person),
          `Deleting person ${person.content.fields.fullName}`);
        await this.documentApiService.patchRecord(
          workspace.workspaceId,
          person.id,
          RecordTypes.person,
          this.getDeletedInstructions());
      }
    }

    // Update existing persons
    multiplier = 50 / members.length;
    for (const member of members) {
      if (member.isServiceMember) {
        // Do not update/add persons for service members
        continue;
      }

      const memberAccess = workspace.members.find(ma => ma.memberId === member.memberId);
      if (!memberAccess) {
        // Do not update/add persons for members that do not have access
        continue;
      }

      let person = personRecords.find(p => p.content.fields.memberId === member.memberId);

      if (!person) {
        person = personRecords.find(p => p.content.fields.email === member.email);
      }

      if (person) {
        if (person.content.fields.workspacePolicy !== memberAccess.workspacePolicy) {
          // Update existing person
          yield new ProgressUpdate(
            50 + multiplier * members.indexOf(member),
            `Updating person ${person.content.fields.fullName}`);
          await this.documentApiService
            .patchRecord(
              workspace.workspaceId,
              person.id,
              RecordTypes.person,
              this.getActiveInstructions(memberAccess.memberId, memberAccess.workspacePolicy))
            .then(p => {
              if (p) {
                // Update the member access with the workspace policy set from the backend
                updatedMemberAccesses.push({
                  memberId: member.memberId,
                  workspacePolicy: p.content.fields.workspacePolicy,
                });
              }
              return p;
            });
        }
      } else {
        // Create new person for non-service members
        yield new ProgressUpdate(
          50 + multiplier * members.indexOf(member),
          `Creating person ${member.name}`);
        await this.documentApiService
          .createRecord(
            this.convertMemberToRecord(member, RecordTypes.person, memberAccess.workspacePolicy),
            workspace.workspaceId);
      }
    }
    yield new UpdatePersonsInWorkspaceResult(updatedMemberAccesses);
  }

    /**
   * Update the service members and service accounts in a workspace. This method will create
   * missing service members and service accounts, update existing persons and unlink persons that
   * are not in the list.
   * @param workspaceId The workspace to update
   * @param members Information about the members
   * @returns The updated members
   * @remarks
   * Service members are not updated.
   */
    async *updateServiceAccountsInWorkspace(
      workspace: Workspace,
      members: Member[]): ProgressIterable<UpdateServiceAccountsInWorkspaceResult> {

      const updatedMemberAccesses: MemberAccess[] = [];

      if (workspace.workspaceId === '') {
        yield new ProgressUpdate(0, 'No workspace to update', false);
        return;
      }

      const serviceAccountRecords = await this.documentApiService.getRecords(
        RecordTypes.serviceAccount,
        workspace.workspaceId);

      // Delete service accounts that are not a service member of the workspace
      let multiplier = 50 / serviceAccountRecords.length;
      for (const serviceAccount of serviceAccountRecords) {
        if (!members.some(m => m.memberId === serviceAccount.content.fields.memberId
            || !m.isServiceMember)) {
          // Delete service account if member has no access or is not a service member
          yield new ProgressUpdate(
            multiplier * serviceAccountRecords.indexOf(serviceAccount),
            `Deleting service account ${serviceAccount.content.fields.fullName}`);
          await this.documentApiService.patchRecord(
            workspace.workspaceId,
            serviceAccount.id,
            RecordTypes.serviceAccount,
            this.getDeletedInstructions());
        }
      }

      // Update existing service accounts
      multiplier = 50 / members.length;
      for (const member of members) {
        if (!member.isServiceMember) {
          // Do not update/add service accounts for members
          continue;
        }

        const memberAccess = workspace.members.find(ma => ma.memberId === member.memberId);
        if (!memberAccess) {
          // Do not update/add service accounts for service members that do not have access
          continue;
        }

        const serviceAccount = serviceAccountRecords.find(p =>
          p.content.fields.memberId === member.memberId);
        if (serviceAccount) {
          // Update existing service account
          yield new ProgressUpdate(
            50 + multiplier * members.indexOf(member),
            `Updating service account ${serviceAccount.content.fields.fullName}`);
          await this.documentApiService
            .patchRecord(
              workspace.workspaceId,
              serviceAccount.id,
              RecordTypes.serviceAccount,
              this.getActiveInstructions(memberAccess.memberId, memberAccess.workspacePolicy))
            .then(p => {
              if (p) {
                // Update the member access with the workspace policy set from the backend
                updatedMemberAccesses.push({
                  memberId: member.memberId,
                  workspacePolicy: p.content.fields.workspacePolicy,
                });
              }
              return p;
            });
        } else {
          // Create new person for non-service members
          yield new ProgressUpdate(
            50 + multiplier * members.indexOf(member),
            `Creating service account ${member.name}`);
          await this.documentApiService
            .createRecord(
              this.convertMemberToRecord(
                member,
                RecordTypes.serviceAccount,
                memberAccess.workspacePolicy),
              workspace.workspaceId);
        }
      }

      yield new UpdateServiceAccountsInWorkspaceResult(updatedMemberAccesses);
    }

  /**
   * Update the persons in workspaces. This method will create missing persons, update existing
   * persons and unlink persons that are not in the list.
   * @param member The member to update
   * @param workspaces The workspaces to update
   * @param workspaceAccesses The new workspace accesses
   * @remarks
   * Service members are not updated.
   */
  async *updatePersonInWorkspaces(
    userMemberId : string,
    member: Member,
    workspaces: Workspace[], // Original workspaces
    workspaceAccesses: WorkspaceAccess[], // New workspace accesses
  ): ProgressIterable<ProgressUpdate> {

    if (member.isServiceMember) {
      // Do not update service members
      return;
    }

    // Remove person in workspaces
    const workspacesWithPersons = workspaces.filter(w => w.type === 'app');
    let progressMultiplier = 50 / workspacesWithPersons.length;
    for (const workspace of workspacesWithPersons) {
      const hasAccess = workspaceAccesses.some(wa => wa.workspaceId === workspace.workspaceId);
      const userHasAccess = workspace.members.some(m => m.memberId === userMemberId
        && (m.workspacePolicy === LevelPolicy.admin || m.workspacePolicy === LevelPolicy.superadmin));

      if (hasAccess || !userHasAccess) {
        // Do not remove person if the member has access or if the workspace is a reference
        // or if the user does not have access
        continue;
      }

      const progress = progressMultiplier * workspacesWithPersons.indexOf(workspace);
      yield new ProgressUpdate(progress, `Deleting person in ${workspace.displayName}`);

      if (!await this.doesWorkspaceSupportPersons(workspace.workspaceId)) {
        continue;
      }

      const persons = await this.documentApiService
        .getRecords(
          RecordTypes.person,
          workspace.workspaceId,
          { where: { "eq": ["fields.memberId", member.memberId] }});

      if (persons.length > 0) {
        // Remove person
        ((await this.documentApiService.patchRecord(
          workspace.workspaceId,
          persons[0].id,
          RecordTypes.person,
          this.getDeletedInstructions())));
      }
    }

    // Update/Add member access in workspaces
    progressMultiplier = 50 / workspaceAccesses.length;
    for (const workspaceAccess of workspaceAccesses) {
      let workspace = workspaces
        .find(w => w.workspaceId === workspaceAccess.workspaceId && w.type === 'app');

      if (!workspace) {
        workspace = await this.workspaceService.getWorkspace(workspaceAccess.workspaceId);
      }

      const userHasAccess = workspace.members.some(m => m.memberId === userMemberId
        && (m.workspacePolicy === LevelPolicy.admin || m.workspacePolicy === LevelPolicy.superadmin));

      const progress = 50 + progressMultiplier * workspaceAccesses.indexOf(workspaceAccess);
      if (!workspace || workspace.type !== 'app' || !userHasAccess) {
        yield new ProgressUpdate(progress, `Skipping ${workspace.displayName}`);
        // Do not update person in reference workspaces or if the user does not have access
        continue;
      }

      yield new ProgressUpdate(progress, `Updating access in ${workspace.displayName}`);

      if (!await this.doesWorkspaceSupportPersons(workspaceAccess.workspaceId)) {
        continue;
      }

      const personRecords = await this.documentApiService.getRecords(
        RecordTypes.person,
        workspaceAccess.workspaceId);

      let person = personRecords.find(p => p.content.fields.memberId === member.memberId);

      if (!person) {
        person = personRecords.find(p => p.content.fields.email === member.email);
      }

      if (person) {
        // Update existing person
        await this.documentApiService
          .patchRecord(
            workspaceAccess.workspaceId,
            person.id,
            RecordTypes.person,
            this.getActiveInstructions(member.memberId, workspaceAccess.workspacePolicy));
      } else {
        // Create new person for non-service members
        ((await this.documentApiService
          .createRecord(
            this.convertMemberToRecord(
              member,
              RecordTypes.person,
              workspaceAccess.workspacePolicy),
            workspaceAccess.workspaceId)));
      }
    }
  }

  /**
   * Update the service account in workspaces. This method will create missing service accounts,
   * update existing service accounts and unlink service accounts that are not in the list.
   * @param member The member to update
   * @param workspaces The workspaces to update
   * @param workspaceAccesses The new workspace accesses
   * @remarks
   * Service members are not updated.
   */
  async *updateServiceAccountInWorkspaces(
    userMemberId : string,
    member: Member,
    workspaces: Workspace[], // Original workspaces
    workspaceAccesses: WorkspaceAccess[], // New workspace accesses
  ): ProgressIterable<ProgressUpdate> {

    if (!member.isServiceMember) {
      // Do not update members
      return;
    }

    // Remove service account in workspaces
    const workspacesWithPersons = workspaces.filter(w => w.type === 'app');
    let progressMultiplier = 50 / workspacesWithPersons.length;
    for (const workspace of workspaces.filter(w => w.type === 'app')) {
      const hasAccess = workspaceAccesses.some(wa => wa.workspaceId === workspace.workspaceId);
      const userHasAccess = workspace.members.some(m => m.memberId === userMemberId
        && (m.workspacePolicy === LevelPolicy.admin
          || m.workspacePolicy === LevelPolicy.superadmin));

      if (hasAccess || !userHasAccess) {
        // Do not remove person if the member has access or if the workspace is a reference
        // or if the user does not have access
        continue;
      }

      const progress = progressMultiplier * workspacesWithPersons.indexOf(workspace);
      yield new ProgressUpdate(progress, `Deleting service account in ${workspace.displayName}`);

      if (!await this.doesWorkspaceSupportServiceAccounts(workspace.workspaceId)) {
        continue;
      }

      const serviceAccounts = await this.documentApiService
        .getRecords(
          RecordTypes.serviceAccount,
          workspace.workspaceId,
          { where: { "eq": ["fields.memberId", member.memberId] }});
      if (serviceAccounts.length > 0) {
        // Remove person
        ((await this.documentApiService.patchRecord(
          workspace.workspaceId,
          serviceAccounts[0].id,
          RecordTypes.serviceAccount,
          this.getDeletedInstructions())));
      }
    }

    // Update/Add member access in workspaces
    progressMultiplier = 50 / workspaceAccesses.length;
    for (const workspaceAccess of workspaceAccesses) {
      let workspace = workspaces
        .find(w => w.workspaceId === workspaceAccess.workspaceId && w.type === 'app');

      if (!workspace) {
        workspace = await this.workspaceService.getWorkspace(workspaceAccess.workspaceId);
      }

      const userHasAccess = workspace.members.some(m => m.memberId === userMemberId
        && (m.workspacePolicy === LevelPolicy.admin
          || m.workspacePolicy === LevelPolicy.superadmin));

      const progress = 50 + progressMultiplier * workspaceAccesses.indexOf(workspaceAccess);
      if (!workspace || workspace.type !== 'app' || !userHasAccess) {
        // Do not update service accounts in reference workspaces
        // or if the user does not have access
        yield new ProgressUpdate(progress, `Skipping ${workspace.displayName}`);
        continue;
      }

      yield new ProgressUpdate(progress, `Updating access in ${workspace.displayName}`);

      if (!await this.doesWorkspaceSupportServiceAccounts(workspaceAccess.workspaceId)) {
        continue;
      }

      const serviceAccountRecords = await this.documentApiService.getRecords(
        RecordTypes.serviceAccount,
        workspaceAccess.workspaceId);

      const serviceAccount = serviceAccountRecords.find(p =>
        p.content.fields.memberId === member.memberId);
      if (serviceAccount) {
        // Update existing service account
        await this.documentApiService
          .patchRecord(
            workspaceAccess.workspaceId,
            serviceAccount.id,
            RecordTypes.serviceAccount,
            this.getActiveInstructions(member.memberId, workspaceAccess.workspacePolicy));
      } else {
        // Create new person for non-service members
        ((await this.documentApiService
          .createRecord(
            this.convertMemberToRecord(
              member,
              RecordTypes.serviceAccount,
              workspaceAccess.workspacePolicy),
            workspaceAccess.workspaceId)));
      }
    }
  }

  private getActiveInstructions(memberId: string, workspacePolicy: string): unknown[] {
    return [
      { op: 'replace', path: '/fields/state', value: 'active' },
      { op: 'replace', path: '/fields/memberId', value: memberId },
      { op: 'replace', path: '/fields/workspacePolicy', value: workspacePolicy },
    ];
  }

  private getDeletedInstructions(): unknown[] {
    return [
      { op: 'replace', path: '/fields/state', value: 'deleted' },
      { op: 'replace', path: '/fields/workspacePolicy', value: '' },
    ];
  }

  private convertMemberToRecord(member: Member, recordType: RecordTypes, workspacePolicy: string): Document {

    const recordId = uuidv4();
    const newPerson: Document = {
      id: recordId,
      documentId: recordId,
      documentType: DocumentTypes.record,
      content: {
        recordType: recordType,
        fields: {
          subType: recordType,
          state: 'active',
          email: member.email,
          fullName: member.name,
          displayName: member.name,
          workspacePolicy: workspacePolicy,
          memberId: member.memberId
        },
        links: {}
      }
    }

    return newPerson;
  }
}
