import { signal, WritableSignal } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

export interface MemberAccess {
  memberId: string;
  workspacePolicy: string;
}

export interface WorkspaceAccess {
  workspaceId: string;
  workspacePolicy: string;
}

export interface Member {
  memberId: string;
  objectId: string;
  name: string;
  email: string;
  platformPolicy: string;
  isServiceMember?: boolean;
  secret?: string;
  tags?: string[];
}

export interface MemberAccess {
  memberId: string;
  workspacePolicy: string;
}

export enum WorkspaceType {
  app = 'app',
  content = 'content',
  config = 'template',
};

export enum WorkspaceState
{
  draft = 'draft',
  active = 'active',
  archived = 'archived',
  deleted = 'deleted',
};

export function getWorkspaceStateFromString(state: string): WorkspaceState {
  const workspaceState = state as WorkspaceState;
  if (workspaceState) {
    return workspaceState;
  }
  return WorkspaceState.active;
}

export function getWorkspaceTypeFromString(type: string): WorkspaceType {
  const workspaceType = type as WorkspaceType;
  if (workspaceType) {
    return workspaceType;
  }
  return WorkspaceType.app;
}

export interface Workspace {
  workspaceId: string;
  displayName: string;
  uriName: string;
  state: WorkspaceState;
  type: WorkspaceType;
  plan: string;
  region: string;
  timeZoneOffset: string;
  lastUpdated: Date;
  description: string;
  publicWorkspacePolicy: string;
  workspaceMemberLimit: number;
  ownerMemberId: string;
  members: MemberAccess[];
  tags: string[];

}

export interface SortState {
  active: string;
  direction: SortDirection;
  disableClear: boolean;
}

export interface WorkspaceIncludeException {
  type: string;
  name?: string;
  language?: string;
  op: string; // 'replace' | 'merge' | 'skip'
  where?: Record<string, string[]>; // which records to apply to
}

export interface WorkspaceInclude {
  id: string;
  default: string; // 'replace' | 'merge' | 'skip'
  op?: string; // obsolite, but 'replace' | 'merge' | 'skip'
  exceptions?: WorkspaceIncludeException[];
}

export class ProgressUpdate {
  constructor(public value: number, public message: string, public isSuccess = true) {
  }
}

export class ProgressState {
  public updates: WritableSignal<ProgressUpdate>;
  constructor(initialMessage: string, public isSuccess = true) {
   this.updates = signal(new ProgressUpdate(0, initialMessage));
  }

  update(value: number | ProgressUpdate, message?: string) {
    if (typeof value === 'number') {
      this.updates.set(new ProgressUpdate(value, message || ''));
      return;
    }
    this.updates.set(value);
  }

  complete(message: string) {
    this.updates.set(new ProgressUpdate(100, message));
  }

  error(message: string) {
    this.updates.set(new ProgressUpdate(100, message, false));
  }
}

export type ProgressIterable<T> = AsyncIterable<ProgressUpdate | T>;

export interface SnackBarData {
  message: string;
  action?: string;
}

export interface ApiQuery {
  select?: unknown;
  where?: unknown;
  limit?: number;
  continuationToken?: string;
}

export function getQueryString(apiQuery?: ApiQuery): string
{
  const query = new URLSearchParams();
  if (apiQuery?.select) {
    query.append('select', JSON.stringify(apiQuery.select));
  }
  if (apiQuery?.where) {
    query.append('where', JSON.stringify(apiQuery.where));
  }
  if (apiQuery?.limit) {
    query.append('limit', apiQuery.limit.toString());
  }
  if (apiQuery?.continuationToken) {
    query.append('continuationToken', apiQuery.continuationToken);
  }
  return query.size > 0 ? '?' + query.toString() : '';
}
