import { Injectable } from '@angular/core';
import { DocumentApiService } from './document-api.service';
import {
  Document,
  ProgressIterable,
  ProgressUpdate,
  RecordContent,
  RecordLink,
  Workspace,
  WorkspaceInclude,
  WorkspaceIncludeException,
  WorkspaceType
} from '@core/models';
import { IdentityService } from './identity.service';
import { WorkspaceService } from './workspace.service';
import { DocumentTypes } from '@shared/reference';
import crc from 'crc';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceInheritanceService {

  constructor(
    private readonly documentApiService: DocumentApiService,
    private readonly identityService: IdentityService,
    private readonly workspaceService: WorkspaceService,
  ) { }

  /**
   * Clean a workspace by deleting all documents and record types.
   * @param workspaceId The workspace to clean
   */
  async *cleanWorkspace(workspaceId: string): ProgressIterable<void> {
    console.log('Cleaning workspace', workspaceId);

    const workspaceDocuments = await this.documentApiService.getAllDocuments(workspaceId);
    const workspaceRecordTypes = await this.documentApiService.getAllRecordTypes(workspaceId);

    const workspaceData = workspaceDocuments
      .find(d => d.documentType === DocumentTypes.workspaceData);

    if (!workspaceData || !workspaceData.content.include) {
      // Skip workspaces that don't have workspace inheritance
      yield new ProgressUpdate(100, 'Skipping workspace without inheritance');
      return;
    }

    for (const document of workspaceDocuments) {
      if (document.documentType === DocumentTypes.workspaceData) {
        // This is specific to a workspace, so remove the other stuff
        document.content = {
          info: document.content.info,
          include: document.content.include,
        };
        yield new ProgressUpdate(
          50 * workspaceDocuments.indexOf(document) / workspaceDocuments.length,
          `Updating workspace data`);
        await this.documentApiService.updateDocument(document, workspaceId);
      }
      else if (document.documentType === DocumentTypes.userData) {
        if (!document.name || document.name === 'default') {
          // This is the default user data, so remove it
          yield new ProgressUpdate(
            50 * workspaceDocuments.indexOf(document) / workspaceDocuments.length,
            `Deleting default user data`);
          await this.documentApiService.deleteDocument(document, workspaceId);
        }
        else {
          // This is specific to a user, so leave it
          continue;
        }
      }
      else {
        yield new ProgressUpdate(
          50 * workspaceDocuments.indexOf(document) / workspaceDocuments.length,
          `Deleting document ${document.name || document.documentType}`);
        await this.documentApiService.deleteDocument(document, workspaceId);
      }
    }

    for (const recordType of workspaceRecordTypes) {
      yield new ProgressUpdate(
        50 + 50 * workspaceRecordTypes.indexOf(recordType) / workspaceRecordTypes.length,
        `Deleting record type ${recordType.name}`);
      await this.documentApiService.deleteRecordType(recordType, workspaceId);
    }
  }

  /**
   * Build a workspace based on the include operations in the workspace data.
   * @param workspaceId
   * @param userMemberId
   * @returns The progress of the operation
   */
  async *buildWorkspace(workspaceId: string, userMemberId = ''): ProgressIterable<void> {
    console.log('Building workspace', workspaceId);

    // Get the workspace data for the include operations
    const workspaceData = await this.documentApiService.getWorkspaceData(workspaceId);
    if (!workspaceData || !workspaceData.content.include) {
      // Skip workspaces that don't have workspace inheritance
      yield new ProgressUpdate(100, 'Skipping workspace without inheritance');
      return;
    }

    // Start with a blank slate
    const workingDocuments: Document[] = [];
    const workingRecordTypes: Document[] = [];
    const records: Document[] = [];

    userMemberId ||= this.identityService.id();

    const includes: WorkspaceInclude[] = workspaceData.content.include;
    if (!includes) {
      return;
    }

    const multiplier = 50 / includes.length;
    for (const include of includes) {

      // Check to make sure we have access to the template
      let template: Workspace | undefined = undefined;
      try {
        template = await this.workspaceService.getWorkspace(include.id);
      } catch {
        yield new ProgressUpdate(100, `Template "${include.id}" not found`, false);
        return;
      }

      if (!template) {
        yield new ProgressUpdate(100, `Template "${include.id}" not found`, false);
        return;
      }

      if (!template.members.some(m => m.memberId === userMemberId && m.workspacePolicy)) {
        yield new ProgressUpdate(
          100,
          `You do not have access to template "${template.displayName}"`,
          false);
        return;
      }

      const defaultOp = include.default || include.op || '<none>';

      if (['replace', 'merge', 'skip'].indexOf(defaultOp) === -1) {
        yield new ProgressUpdate(
          100,
          `Unknown default: ${defaultOp} for template "${template.displayName}"`,
          false);
        return;
      }

      if (template.type === WorkspaceType.config) {
        yield new ProgressUpdate(
          multiplier * includes.indexOf(include),
          `Combining with configuration ${template.displayName}`);

        const error = await this.combineWithTemplate(
          defaultOp,
          include.exceptions,
          include.id,
          workingDocuments,
          workingRecordTypes);
        if (error) {
          yield new ProgressUpdate(100, error.message, false);
          return;
        }
      }
      else if (template.type === WorkspaceType.content) {
        yield new ProgressUpdate(
          multiplier * includes.indexOf(include),
          `Combining with content ${template.displayName}`);

        const error = await this.combineWithContent(
          defaultOp,
          include.exceptions,
          include.id,
          records);
        if (error) {
          yield new ProgressUpdate(100, error.message, false);
          return;
        }
      }
    }

    for await (const result of this.updateWorkspace(
      workspaceId,
      workingDocuments,
      workingRecordTypes,
      records)) {
      if (result instanceof ProgressUpdate) {
        if (!result.isSuccess) {
          yield new ProgressUpdate(100, result.message, false);
          return;
        }
        yield new ProgressUpdate(50 + result.value * 0.5, result.message);
      }
    }

    // Update the workspace with the last updated time
    const workspace = await this.workspaceService.getWorkspace(workspaceId);
    workspace.lastUpdated = new Date();
    await this.workspaceService.updateWorkspace(workspace);
  }

  async combineWithTemplate(
    defaultOp: string,
    exceptions: { type: string; name?: string; language?: string; op: string; }[] | undefined,
    templateId: string,
    workingDocuments: Document[],
    workingRecordTypes: Document[],
  ): Promise<Error | null> {

    const templateDocuments = await this.documentApiService.getAllDocuments(templateId);
    const templateRecordTypes = await this.documentApiService.getAllRecordTypes(templateId);

    for (const templateDocument of templateDocuments) {
      const exception = exceptions
        ?.find(e => e.type === templateDocument.documentType
          && (!e.name || e.name === templateDocument.name)
          && (!e.language || e.language === templateDocument.language));
      const op = exception?.op || defaultOp;

      if (['replace', 'merge', 'skip'].indexOf(op) === -1) {
        return new Error(`Unknown operation: ${op} for template ${templateId}`
          + ` document type:${templateDocument.documentType}`
          + `, name:${templateDocument.name || '*'}`
          + `, language:${templateDocument.language || '*'}`);
      }

      if (op === 'skip') {
        continue;
      } else {
        const document = workingDocuments.find(d => d.name === templateDocument.name
          && d.documentType === templateDocument.documentType
          && d.language === templateDocument.language);

        if (document) {
          if (op === 'replace') {
            document.content = templateDocument.content;
          } else if (op === 'merge') {
            document.content = this.mergeDeep(document.content, templateDocument.content);
          }
        } else {
          const newDocument: Document = {
            id: '',
            documentId: '',
            documentType: templateDocument.documentType,
            name: templateDocument.name,
            language: templateDocument.language,
            content: { ...templateDocument.content },
          };
          if (newDocument.documentType === 'workspaceData') {
            // This is specific to a workspace, so remove it
            newDocument.content.info = {};
            newDocument.content.include = {};
          }
          workingDocuments.push(newDocument);
        }
      }
    }

    for (const templateRecordType of templateRecordTypes) {
      if (!templateRecordType.name) {
        continue;
      }

      const op = exceptions
        ?.find(e => e.type === templateRecordType.documentType
          && (!e.name || e.name === templateRecordType.name)
          && (!e.language || e.language === templateRecordType.language))?.op
        || defaultOp;

      if (['replace', 'merge', 'skip'].indexOf(op) === -1) {
        return new Error(`Unknown operation: ${op} for template ${templateId}`
          + ` document type:${templateRecordType.documentType}`
          + `, name:${templateRecordType.name}`);
      }

      if (op === 'skip') {
        continue;
      } else {
        const recordType = workingRecordTypes.find(rt => rt.name === templateRecordType.name);

        if (recordType) {
          if (op === 'replace') {
            recordType.content = templateRecordType.content;
          } else if (op === 'merge') {
            recordType.content = this.mergeDeep(recordType.content, templateRecordType.content);
          }
        } else {
          const newRecordType: Document = {
            id: '',
            documentId: '',
            documentType: templateRecordType.documentType,
            name: templateRecordType.name,
            language: templateRecordType.language,
            content: { ...templateRecordType.content },
          };
          workingRecordTypes.push(newRecordType);
        }
      }
    }
    return null;
  }

  async combineWithContent(
    defaultOp: string,
    exceptions: WorkspaceIncludeException[] | undefined,
    templateId: string,
    records: Document[]): Promise<Error | null> {

    const recordTypes = await this.documentApiService.getAllRecordTypes(templateId);
    for (const recordType of recordTypes) {
      if (!recordType.name) {
        continue;
      }

      const exception = exceptions?.find(e => e.type === recordType.name);
      const op = exception?.op || defaultOp;
      const filter = exception?.where;

      if (['replace', 'skip'].indexOf(op) === -1) {
        return new Error(`Unknown operation: ${op} for template ${templateId}`
          + ` record type:${recordType.name}`);
      }

      if (op === 'skip') {
        continue;
      }

      // TODO: Support merging records, but for now just replace

      const templateRecords = await this.documentApiService.getRecords(
        recordType.name,
        templateId,
        filter ? { where: filter } : {});

      records.push(...templateRecords.map(r => {
        if (!r.content.attributes) {
          r.content.attributes = {};
        }
        const checksum = this.generateCRC(r.content.fields, r.content.links);
        r.content.attributes.inheritDocumentId = r.documentId;
        r.content.attributes.inheritWorkspaceId = templateId;
        r.content.attributes.inheritChecksum = checksum;
        return r;
      }));
    }
    return null;
  }

  async *updateWorkspace(
    workspaceId: string,
    documents: Document[],
    recordTypes: Document[],
    records: Document[]): ProgressIterable<void> {

    yield new ProgressUpdate(0, 'Updating workspace');

    // Get all the documents and record types in the workspace
    const workspaceDocuments: Document[] = await this.documentApiService
      .getAllDocuments(workspaceId);
    const workspaceRecordTypes: Document[] = await this.documentApiService
      .getAllRecordTypes(workspaceId);

    // Update or create the documents
    let multiplier = 33 / documents.length;
    for (const document of documents) {
      const workspaceDocument = workspaceDocuments.find(d => d.name === document.name
        && d.documentType === document.documentType
        && d.language === document.language);
      if (workspaceDocument) {
        document.id = workspaceDocument.id;
        document.documentId = workspaceDocument.documentId;
        if (document.documentType === 'workspaceData') {
          // This is specific to a workspace, so remove it
          document.content.info = workspaceDocument.content.info;
          document.content.info.lastUpdated = new Date().toISOStringWithTimezone();
          document.content.include = workspaceDocument.content.include;
        }
        yield new ProgressUpdate(
          multiplier * documents.indexOf(document),
          `Updating document ${document.name || document.documentType}`);
        await this.documentApiService.updateDocument(document, workspaceId);
      } else {
        yield new ProgressUpdate(
          multiplier * documents.indexOf(document),
          `Creating document ${document.name || document.documentType}`);
        await this.documentApiService.createDocument(document, workspaceId);
      }
    }

    // Update or create the record types
    multiplier = 33 / recordTypes.length;
    for (const recordType of recordTypes) {
      const workspaceRecordType = workspaceRecordTypes.find(rt => rt.name === recordType.name);
      if (workspaceRecordType) {
        recordType.id = workspaceRecordType.id;
        recordType.documentId = workspaceRecordType.documentId;
        yield new ProgressUpdate(
          33 + multiplier * recordTypes.indexOf(recordType),
          `Updating record type ${recordType.name}`);
        await this.documentApiService.updateRecordType(recordType, workspaceId);
      } else {
        yield new ProgressUpdate(
          33 + multiplier * recordTypes.indexOf(recordType),
          `Creating record type ${recordType.name}`);
        await this.documentApiService.createRecordType(recordType, workspaceId);
      }
    }

    // Update or create the records
    multiplier = 34 / records.length;
    for (const record of records) {
      yield new ProgressUpdate(
        66 + multiplier * records.indexOf(record),
        `Checking record ${record.id}`);
      const workspaceRecords = await this.documentApiService.getRecords(
        record.content.recordType,
        workspaceId,
        {
          where: {
            "and": [
              {
                "eq": [
                  "attributes.inheritDocumentId",
                  record.documentId
                ],
              },
              {
                "eq": [
                  "attributes.inheritWorkspaceId",
                  record.workspaceId
                ],
              },
            ]
          }
        });
      if (workspaceRecords.length > 0) {
        const workspaceRecord = workspaceRecords[0];
        const workspaceInheritChecksum = workspaceRecord.content.attributes.inheritChecksum;
        const workspaceRecordChecksum = this.generateCRC(
          workspaceRecord.content.fields,
          workspaceRecord.content.links);

        if (workspaceInheritChecksum === workspaceRecordChecksum) {
          // The workspace record has not changed, we can modify it
          const recordContent = record.content as RecordContent;

          record.id = workspaceRecord.id;
          record.documentId = workspaceRecord.documentId;
          record.workspaceId = workspaceId;
          record.owners = workspaceRecord.owners;

          // Remove any links where the uri starts with 'document:'
          for (const key in recordContent.links) {
            record.content.links[key] = record.content.links[key]
              ?.filter((link: RecordLink) => !link.uri.startsWith('documents:'));
          }

          yield new ProgressUpdate(
            66 + multiplier * records.indexOf(record),
            `Updating record ${record.id}`);
          await this.documentApiService.updateRecordForInheritance(record);
        }
      }
      else {
        yield new ProgressUpdate(
          66 + multiplier * records.indexOf(record),
          `Creating record ${record.id}`);

        const recordContent = record.content as RecordContent;

        // Modify the record for the workspace
        record.id = '';
        record.documentId = '';
        record.workspaceId = workspaceId;
        record.owners = [];

        // Remove any links where the uri starts with 'document:'
        for (const key in recordContent.links) {
          record.content.links[key] = record.content.links[key]
            ?.filter((link: RecordLink) => !link.uri.startsWith('documents:'));
        }

        await this.documentApiService.createRecordForInheritance(record);
      }
    }

    yield new ProgressUpdate(100, 'Workspace updated');
  }

  private generateCRC(fields: object, links: object): string {
    const jsonString = JSON.stringify({ fields, links });
    return crc.crc32(jsonString).toString(16);
  }

  private isObject(item: unknown) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

}
